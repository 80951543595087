import { Title } from '@solidjs/meta';
import { Container, Heading, Page, Section } from '@troon/ui';
import { createAsync, useParams } from '@solidjs/router';
import { For, Suspense } from 'solid-js';
import { createContentfulRequest } from '../../../content/[model]/_client';
import { ContentCard } from '../../../../components/card';

export default function CardList() {
	const params = useParams<{ id: string }>();
	const cards = createAsync(async () => getCards(params.id), { deferStream: true });

	return (
		<Container>
			<Page>
				<Title>Card List | Previews | Troon</Title>
				<Heading as="h1">Card List Preview</Heading>

				<Section class="mb-12 grid grid-cols-2 gap-4 md:gap-8 lg:grid-cols-3">
					<Suspense>
						<For each={cards()?.fields.cards}>
							{(card) => {
								return <ContentCard {...card!.fields} level="h4" />;
							}}
						</For>
					</Suspense>
				</Section>

				<Section class="rounded border border-neutral bg-neutral-100 p-6">
					<Heading as="h2" size="h4">
						Metadata
					</Heading>
					<table>
						<Suspense>
							<tbody>
								<tr>
									<th>Title</th>
									<td>{cards()?.fields.title}</td>
								</tr>
								<tr>
									<th>Slug</th>
									<td>{cards()?.fields.slug}</td>
								</tr>
								<tr>
									<th>ID</th>
									<td>{cards()?.sys.id}</td>
								</tr>
							</tbody>
						</Suspense>
					</table>
				</Section>
			</Page>
		</Container>
	);
}

const getCards = createContentfulRequest('cards');
